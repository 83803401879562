import React from "react"
import Seo from "../components/seo"
import {useIsMobile} from "../utils";
import {motion} from "framer-motion";
import Hero from "../components/hero";
import Story from "../components/story";
import Contact from "../components/contact";

const CenterStory = ({ children, title, imageUrl }) => (
  <div
      style={{
          padding: '4rem 4rem',
          maxWidth: 700,
          textAlign: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          margin: '0px auto',
      }}
  >
    <div style={{padding: '0px 32px' }}>
      <div style={{fontSize: 32}}>
        {title}
      </div>
      {children}
    </div>
    <img src={imageUrl} style={{width: '100%', height: 'auto', marginTop: 32}}/>
  </div>
)

const Description = ({children}) => <motion.div
    style={{
        display: 'flex',
        margin: '48px auto',
    }}
>
    {children}
</motion.div>


const About = ({
                   data,
                   pageContext: {nextPagePath, previousPagePath},
               }) => {
    const {isMobile} = useIsMobile();
    return (
        <div>
            <Seo title="關於我們"/>
            <Hero imageUrl="/about-pic1.jpg"/>
            <div
                style={{
                    padding: '4rem 4rem 0 4rem',
                    maxWidth: 657,
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0px auto',
                }}
            >
                ABOUT
                <br/>
                <div style={{fontSize: 40}}>
                    關於我們
                </div>
                <div style={{textAlign: 'justify', padding: isMobile ? '0 16px' : '64px 0'}}>
                  Cooperation Coffee 是一家位於台灣的咖啡公司。合作咖啡成立於2017年，我們從網路販售 咖啡豆開始。一直到2019年我們有了第一家實體的咖啡店，店鋪座落在台
灣的台中市南區。
                  <br />
                  <br />
                  Cooperation 在咖啡產鏈中是合作社的意思，我們想表達的是從產區與果農合作、烘豆師烘焙、咖啡師沖煮，最後再到客人手上，這是一個咖啡生產鏈，也代表著合作的關係，正是我們品牌名稱的取名。
                  <br />
                    <br />
                    我們認為一杯好咖啡，從果農種植採收咖啡豆開始，經過尋豆師對生豆的嚴格挑選，烘豆師對咖啡豆烘焙程度的判斷，咖啡師對每一杯咖啡沖煮的要求以及傳達烘豆師想表現的風味，並透過專業與分享的方式呈現這杯咖啡。最後到客人的手上，經歷了層層關卡，都是我們對品質的堅持把關。 <br />
                </div>
            </div>
            <br/>
            <div style={{
              maxWidth: 1100,
              margin: '0px auto'
            }}>
              <Story
                  imageUrl="/about-pic2.jpg"
                  header="嚴謹的生豆採購"
                  content={<p style={{ textAlign: "justify"}}>
                    合作咖啡致力於提供當季新鮮的咖啡豆給我們
  的客人。在挑選生豆的環節中，我們會針對當季量產的咖啡豆進行採購。生豆的採購會考量到產地的氣候、生⻑環境、水資源、戰亂、品種以及處理法，優質的咖啡生豆對於生⻑環境的挑選極為嚴格，也跟當地的水資源充沛與否息息相關。 <br /><br />
  咖啡豆就像水果一樣，希望通過我們用心的烘焙及沖煮，盡可能地呈現咖啡收穫的季節性，提供最新鮮的咖啡體驗。同時我們在挑選生豆時會選擇國內外信任的生豆商做採購，並選擇獨特且適合我們的咖啡豆作烘焙。
                  </p>}
              />
              <Story
                  imageUrl="/about-pic3.jpg"
                  header="烘焙程度的判斷"
                  direction="left"
                  content={<p style={{ textAlign: "justify"}}>
                    我們烘焙的目的是為了提高這些優質咖啡豆的內在品質及風味，以最透明的方式呈現果農精心的種植。這種方法要求我們在咖啡烘焙的過程中，密切地關注咖啡豆的均衡發展，並且我們強調咖啡豆厚實的body、甜感的表現與層次的飽滿度，讓每一位購買咖啡豆的客人回家沖煮也能簡單輕鬆地沖出一杯好喝的咖啡。希望客人在品嚐我們咖啡的時候，從溫度高至慢慢降溫，每一口都有著豐富的層次跟甜感。
                  </p>}
              />
              <Story
                header="追求咖啡豆的新鮮度"
                imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/about-fresh.jpg"
                content={
                  <div style={{textAlign: 'justify', padding: isMobile ? '0 16px' : '64px 0'}}>
                    每週我們會進行兩至三次的烘焙，並記錄烘焙過程中的每一個配置。完成後都會對每一款咖 啡豆進行杯測和評分，討論我們的烘焙曲線是否需要做調整。藉此不斷地精進烘焙的品質，並確保咖啡豆的穩定性。每一次在烘焙完成後，都會在48小時內裝袋，以確保咖啡豆有良好的保存。這也證明我們對每一包咖啡豆的要求。
                  <br />
                  <br />
    最後，我們會在豆袋外明確標示咖啡豆的名稱和烘焙的日期，並附上完整的咖啡豆資訊交到 每一位客人手上。合作咖啡提供的高品質服務，期待所有購買咖啡豆的客人都能感受到我們的用心。
    </div>
                }
              />
              <Story
                  imageUrl="/about-pic4.jpg"
                  header="包裝設計"
                  direction="left"
                  content={<p style={{ textAlign: "justify"}}>
                    一直以來合作咖啡不斷的挑戰創新，在咖啡豆袋的設計上，透過插畫來說故事，讓大家更容易接近並認識合作咖啡，所以誕生了狗狗、熊熊、鵜鶘鳥這三個角色，他們分別在合作咖啡中有著不同的工作職責。讓購買我們豆子的每一位客人不單只是購買一包咖啡豆，同時也可以與朋友分享我們的故事。
                  </p>}
              />
              <Story
                  imageUrl="/about-pic5.jpg"
                  header="三位好夥伴"
                  content={<p style={{ textAlign: "justify"}}>
                      <div style={{ fontWeight: 'bold', fontSize: 18}}>
                          鵜鶘鳥 - 尋豆師
                      </div>
                      <br />
                      鵜鶘鳥扮演著農民最佳的工作拍檔，他在產區找尋優質且有特色的咖啡豆，再利用他大大的喉囊跟擅於飛行的翅膀，把每一顆飽滿又紅潤的咖啡果實運送到城市，讓烘豆師可以收到最棒的咖啡豆！
                      <br />
                      <br />
                      <div style={{ fontWeight: 'bold', fontSize: 18}}>
                          熊熊 - 烘豆師
                      </div>
                      <br />
                      熊熊天生就是嗅覺高手，他可以在森林裡尋找哪裡有香甜濃郁的蜂蜜，也能在湍急的河流裡聞出哪一條才是最新鮮的鮭魚。當然！也能找到最佳的烘焙程度，讓客人品嘗到最棒最香的咖啡。
                      <br />
                      <br />
                      <div style={{ fontWeight: 'bold', fontSize: 18}}>
                          狗狗 - 咖啡店長
                      </div>
                      <br />
                      他工作是負責沖煮咖啡、招呼咖啡廳的客人，靈敏的狗狗善於感知環境打點與顧客需求，是大家最好的朋友，要確保咖啡廳裡的每一位客人的感受是舒適的，從進門開始音樂的篩選到音量調節，亦或是店內溫度與氣氛都他都控制得非常的好。

                  </p>}
              />
              <Story
                  imageUrl="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/about-best.jpg"
                  header="合作出一杯最棒的咖啡"
                  direction="left"
                  content={<div style={{ textAlign: "justify"}}>
                    <div>
                      合作咖啡是由狗狗店⻑與熊熊烘豆師、鵜鶘鳥尋豆師三位透過和客人交流與了解需求後，從採購咖啡豆，到烘焙程度判斷，選擇最適合客人的。在沖煮咖啡的時候，沖煮的溫度、研磨顆細度、時間、每一個環節都是非常重要，並且忠誠地表達烘豆師對於咖啡豆風味的堅持 !
                    </div>
                    <br />
                    <div>
                      我們認為咖啡是人與人接觸的媒介，一杯咖啡如果有70%是咖啡，那剩 下的30%就是人與人之間的交流與合作，這樣才能讓這杯咖啡美味且完整。
                    </div>
                  </div>}
              />
            </div>
            <div style={{ height: 64}} />l
            <Contact />
        </div>
    )
}

export default About;
